/*
 * @Author: li 2696915640@qq.com
 * @Date: 2023-04-18 09:25:23
 * @LastEditors: li 2696915640@qq.com
 * @LastEditTime: 2023-05-10 10:33:10
 * @FilePath: \ChatGPT\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    //路由初始指向
    path: '/',
    name: 'HelloWorld',
    component: () => import('@/view/homePage/index.vue'),
    meta: {
      //是否拦截 true拦截 false 不拦截
      requiresAuth: true,
    },
  },
  {
    //会议
    path: '/conference',
    name: 'conference',
    component: () => import('@/view/contentMeeting/index.vue'),
    meta: {
      //是否拦截 true拦截 false 不拦截
      requiresAuth: true,
    },
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: () => import('@/view/LoginPage/index.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

import { useUsersStore } from "@/store/user";
//路由拦截
router.beforeEach((to, from, next) => {
  const state = useUsersStore()//获取存储token
  //当当前页面需要拦截并且没有token时进入登录也
  if (to.meta.requiresAuth && !state.token) {
    router.push("/login");//跳转登录
  } else {
    next();
  }

});

export default router
