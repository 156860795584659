

import { defineStore } from 'pinia'
export const useUsersStore = defineStore('users', {
  state: () => {
    return {
      token: '',//登录存储的token当前为ok
      phone: '',//手机号
      id: '',//id
      dataList: [], //数据列表
      //会议人员数据
      meeting_participant: []
    }
  },
  // 开启数据缓存
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'myCounter', // 存储的 key 值，默认为 storeId
        storage: localStorage, // 存储的位置，默认为 sessionStorage
      }
    ]
  }

})